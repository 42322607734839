import { useState } from 'react'
import style from './ShortcutUrlGenerator.module.scss'
import { useTranslation } from 'react-i18next'
import { db } from '../../firebase'
import { setDoc, doc, getDoc } from 'firebase/firestore'
import { isValidUrl, generateNanoId } from '../../utils/helpers'
import useAuth from '../../hooks/useAuth'

import Switch from 'components/Switch/Switch'

import Metadata from '../../utils/metadata'

// 短網址生成器
function ShortcutUrlGenerator() {
  const { currentUser } = useAuth()
  const { t } = useTranslation()
  const [originalUrl, setOriginalUrl] = useState('')
  const [shortUrl, setShortUrl] = useState('')
  const [customCode, setCustomCode] = useState('')
  const [isInvalidUrl, setIsInvalidUrl] = useState(true)
  const [isCustomCode, setIsCustomCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const [errorMessage, setErrorMessage] = useState('')

  // 生成短網址
  const generateShortUrl = async () => {
    do {
      let shortCode = generateNanoId()

      if (isCustomCode) {
        shortCode = customCode
      }

      try {
        // 先檢查文件是否存在
        const docRef = doc(db, 'shortUrls', shortCode)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          setErrorMessage(
            t('error.customCodeExists', { ns: 'shortcutUrlGenerator' })
          )
          console.warn(`Collision detected for ID: ${shortCode}`)
          throw new Error('already-exists')
        }

        await setDoc(docRef, {
          originalUrl,
          createdAt: new Date(),
          createdBy: currentUser.uid,
        })

        const domain = 'https://johnlin.me'
        setShortUrl(`${domain}/r/${shortCode}`)
        setErrorMessage('')
        break
      } catch (error) {
        if (error.message === 'already-exists') {
          if (isCustomCode) {
            setErrorMessage(
              t('error.customCodeExists', { ns: 'shortcutUrlGenerator' })
            )
            setShortUrl('')
            break
          }
          console.warn(`Collision detected for ID: ${shortCode}`)
        } else {
          console.error('add short url error', error)
          setErrorMessage(
            t('error.unknownError', { ns: 'shortcutUrlGenerator' })
          )
          setShortUrl('')
          break
        }
      }
    } while (!isCustomCode)
  }

  /**
   * 當輸入網址時，檢查是否為有效 URL
   * @param {*} e
   */
  const urlInputChange = (e) => {
    const url = e.target.value
    setOriginalUrl(url)
    // setErrorMessage('')

    if (!isValidUrl(url)) {
      setIsInvalidUrl(true)
    } else {
      setIsInvalidUrl(false)

      if (url.length < 32) {
        setIsInvalidUrl(true)
        // setErrorMessage(
        //   t('error.lengthTooShort', { ns: 'shortcutUrlGenerator' })
        // )
      }
    }
  }

  /**
   * 當輸入自訂代碼時，檢查是否為有效代碼
   * @param {*} e
   */
  const customCodeInputChange = (e) => {
    const code = e.target.value
    setCustomCode(code)
  }

  return (
    <>
      <Metadata
        title={`${t('title', { ns: 'shortcutUrlGenerator' })} | ${t('title', {
          ns: 'laboratory',
        })}`}
        description={t('description', { ns: 'shortcutUrlGenerator' })}
      />
      <div className={style.shortcutUrlGenerator}>
        {/* {errorMessage && <p>{errorMessage}</p>} */}
        <input
          type="text"
          value={originalUrl}
          onChange={urlInputChange}
          placeholder={t('content.inputUrl', { ns: 'shortcutUrlGenerator' })}
        />

        {!isInvalidUrl && (
          <>
            <div className={style.switchContainer}>
              <Switch
                checked={isCustomCode}
                onChange={() => setIsCustomCode(!isCustomCode)}
              />
              <p>{t('content.customCode', { ns: 'shortcutUrlGenerator' })}</p>
            </div>
            {isCustomCode && (
              <>
                <input
                  type="text"
                  value={customCode}
                  onChange={customCodeInputChange}
                  placeholder={t('content.inputCustomCode', {
                    ns: 'shortcutUrlGenerator',
                  })}
                />
                <p className={style.description}>
                  {t('content.customCodeLimit', { ns: 'shortcutUrlGenerator' })}
                </p>
              </>
            )}
          </>
        )}
        <div className={style.panelContainer}>
          {errorMessage && <p>{errorMessage}</p>}
          {shortUrl && (
            <p>
              {t('content.shortUrl', { ns: 'shortcutUrlGenerator' })}:{' '}
              <a href={shortUrl} target="_blank" rel="noreferrer">
                {shortUrl}
              </a>
            </p>
          )}
          <button
            onClick={generateShortUrl}
            disabled={isInvalidUrl}
            title={t('error.lengthTooShort', { ns: 'shortcutUrlGenerator' })}
          >
            {t('content.generate', { ns: 'shortcutUrlGenerator' })}
          </button>
        </div>
      </div>
    </>
  )
}

export default ShortcutUrlGenerator
